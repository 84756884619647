import Component from '../core/Component'
import support from '../utils/BrowserSupport'

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
}

export default class ModalAge extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            layer: null,
            close: null
        }

    }

    prepare() {
        this.ref.close.addEventListener('click', this.handleClick)
        this.show()
    }

    destroy() {
        this.ref.close.removeEventListener('click', this.handleClick)
    }

    handleClick = event => {
        this.hide()
    }

    show() {
        this.element.classList.add(STATUS.VISIBLE)
        this.element.offsetWidth
        this.element.classList.add(STATUS.ACTIVE)

        this.ref.layer.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATUS.ACTIVE)) {
                this.selfRemove()
            }
        })
    }

    hide() {
        this.element.classList.remove(STATUS.ACTIVE);
        setTimeout(() => {
            this.element.classList.remove(STATUS.VISIBLE);
        }, 200);
    }

    selfRemove() {
        this.element.parentNode.removeChild(this.element)
    }
}
